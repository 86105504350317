<template>
  <div class="crm f-flex">
    <div class="crm-name">登錄日誌</div>
  </div>

  <!-- <div class="s-box f-flex">
    <div class="s-t">時间：</div>
    <div class="f-plr10">
      <select class="ant-select">
        <option value="1">1天內</option>
        <option value="7">7天內</option>
        <option value="15">15天內</option>
        <option value="30">30天內</option>
      </select>
    </div>
    <button class="btn-blue">查询</button>
  </div> -->

  <div class="table-con">
    <table class="m-table">
      <tr>
        <th style="width: 120px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">类别</th>
        <th style="width: 150px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">登錄時间</th>
        <th style="width: 150px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">登錄IP</th>
        <th style="width: 150px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">來源域名</th>
      </tr>

      <tr v-for="(item, index) in records.data" :key="index">
        <td style="width: 120px">會員登入</td>
        <td style="width: 150px">{{ formatTime(item.gmtCreate, "YYYY-MM-DD HH:mm:ss") }}</td>
        <td style="width: 150px">{{ item.loginIp }}</td>
        <td style="width: 150px">{{ item.loginDomain }}</td>
      </tr>
      <!-- <tr v-else>
        <td style="width: 100%" colspan="3">暫無數據</td>
      </tr> -->
    </table>
    <XtxPagination
      v-if="records.total > 0"
      :current-page="query.pageNo"
      :page-size="query.pageSize"
      :total="records.total"
      @current-change="pageChangeEvent"
    />
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  reactive,
  nextTick,
  computed,
  watch,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
export default {
  name: "LhcLog",
  setup() {
    const store = useStore();
    const loading = ref(false);
    let query = reactive({
      pageNo: 1,
      pageSize: 20,
      dto: {
        dateRank: 1,
      },
    });
    let records = reactive({
      total: 0,
      data: [],
    });
    onMounted(() => {
      nextTick(() => {
        requestLoginRecord();
      });
    });

    //获取投註明細
    const requestLoginRecord = () => {
      loading.value = true;
      store
        .dispatch("player/requestLoginRecordPageAction", query)
        .then((res) => {
          loading.value = false;
          if (res && res.code == 0) {
            records.data = res.res.rows;
            records.total = res.res.total;
          } else {
            records.data = [];
            records.total = 0;
          }
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const pageChangeEvent = ($event) => {
      query.pageNo = $event;
      requestLoginRecord();
    };

    const selectChangeEvent = ($event) => {
      query.dto.betType = $event.target.value;
      query.pageNo = 1;
      requestLoginRecord();
    };

    //格式化時间
    const formatTime = (time, format) => {
      return getCurrentInstance()
        .appContext.config.globalProperties.$moment(time)
        .format(format);
    };
    const mathFloor = (num, multiple) => {
      return Math.floor(num * multiple) / multiple;
    };
    return {
      loading,
      records,
      query,
      formatTime,
      pageChangeEvent,
      selectChangeEvent,
      mathFloor,
    };
  },
};
</script>